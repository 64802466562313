<template>
    <div
        style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
        :title="convertHtml(inputVal.description)"
        v-html="convertHtml(inputVal.description)">
    </div>
</template>

<script>
export default {
    name: 'DescriptionColumn',
    props: {
        value: [Object],
        savePosition: [Function],
    },
    methods: {
        convertHtml(value) {
            const div = document.createElement('div')
            div.innerHTML = value
            const text = div.textContent || div.innerText || ''
            return text
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>